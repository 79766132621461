<template>
  <div>
    <h5 class="mb-3">{{ title }}</h5>
    <b-row>
      <b-col md="6">
        <b-card>
          <div class="h4 m-0">{{ this.data.count }}</div>
          <small>Хэрэглэгчийн тоо</small>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <div>
            <span class="pull-right badge bg-primary mt-1"
              >{{
                ((parseFloat(this.data.amount.replace(/,/g, "")) /
                  total.amount) *
                  100)
                  | currecry
              }}%</span
            >
            <div class="h4 m-0">{{ this.data.amount }}₮</div>
          </div>
          <small>Нийт зээлийн дүн</small>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Card from "./Card";
import moment from "moment";

export default {
  name: "OverdueLoan",
  components: {
    Card,
  },
  props: {
    title: {
      type: String,
      default: "Хугацаа хэтэрсэн зээл",
    },
    data: {
      type: Object || Array,
      default: () => [],
    },
    totals: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    total: {
      count: 0,
      amount: 0,
    },
  }),
  created() {
    this.$data.total = {
      count: 0,
      amount: 0,
    };
    //console.log(this.totals)
    for (var i in this.totals) {
      this.$data.total.count += parseInt(this.totals[i][1].replace(/,/g, ""));
      this.$data.total.amount += parseFloat(
        this.totals[i][2].replace(/,/g, "")
      );
    }
  },
};
</script>
