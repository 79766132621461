<template>
  <div class="animated fadeIn">
    <b-card class="border-0 bg-gray-200 mr-n4 ml-n4 mt-n4">
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity"
        :background-color="this.$config.LOADER.backgroundColor"
        :z-index="10000"
      ></loading>
      <b-row v-if="this.checkPermission('admin.dashboard.update')">
        <b-col class="mb-3">
          <b-button
            variant="primary"
            size="sm"
            class="pull-right"
            @click="loadData()"
          >
            <i class="fa fa-refresh"></i> шинэчлэх
          </b-button>
          <p class="mb-0 pull-right mr-3 mt-1 text-muted">
            <strong>Сүүлд шинэчлэх огноо :</strong>
            {{ dashboard.last_update_date }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <Card
            label="Бүртгүүлсэн хэрэглэгчийн тоо"
            type="REGISTERED_CUSTOMER_COUNT"
            bg_color="bg-primary"
            text_color="text-white"
            :data="dashboard.data[0]"
            v-if="this.checkPermission('admin.dashboard.all.resitered_count')"
          ></Card>
        </b-col>
        <b-col>
          <Card
            label="Гэрээ хийсэн хэрэглэгчийн тоо"
            type="CONTRACTED_CUSTOMER_COUNT"
            bg_color="bg-blue"
            text_color="text-white"
            :data="dashboard.data[1]"
            v-if="this.checkPermission('admin.dashboard.all.contracted_count')"
          ></Card>
        </b-col>
        <b-col>
          <Card
            label="Гарсан зээлийн тоо"
            type="LOAN_COUNT"
            bg_color="bg-danger"
            text_color="text-white"
            :data="dashboard.data[2]"
            v-if="this.checkPermission('admin.dashboard.all.loan_count')"
          ></Card>
        </b-col>
        <b-col>
          <Card
            label="Гарсан зээлийн хэмжээ"
            type="LOAN_AMOUNT"
            value_type="amount"
            :data="dashboard.data[3]"
            v-if="this.checkPermission('admin.dashboard.all.loan_amount')"
          ></Card>
        </b-col>
        <b-col>
          <Card
            label="Орсон эргэн төлөлтийн хэмжээ"
            type="REPAYMENT_AMOUNT"
            bg_color="bg-gray"
            text_color="text-white"
            value_type="amount"
            :data="dashboard.data[4]"
            v-if="this.checkPermission('admin.dashboard.all.repayment_amount')"
          ></Card>
        </b-col>
      </b-row>
      <ActiveLoanCount
        :data="dashboard.data[5]"
        v-if="this.checkPermission('admin.dashboard.active_loan_count')"
      ></ActiveLoanCount>
      <b-row>
        <!-- <b-col md=4 sm=12> -->
        <!-- <OverdueLoan :data="{'count':dashboard.data[6][0], 'amount': dashboard.data[6][1]}" :totals="dashboard.data[5]" v-if="this.checkPermission('admin.dashboard.overdue_loan')"></OverdueLoan> -->
        <!-- </b-col> -->
        <b-col md="12" sm="12">
          <Star
            :data="{
              count: dashboard.data[13],
              complete_count: dashboard.data[14],
              complete_amount: dashboard.data[15],
            }"
            v-if="this.checkPermission('admin.dashboard.star')"
          ></Star>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- <OverdueLoanType
            v-if="this.checkPermission('admin.dashboard.overdue_loan_type')"
          ></OverdueLoanType> -->
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <Chart
            title="Хэрэглэгчийн тоо"
            :legends="[
              'Бүртгүүлсэн хэрэглэгчийн',
              'Гэрээ хийсэн хэрэглэгчийн тоо',
            ]"
            type="REGISTERED_CUSTOMER_COUNT"
            :data="[dashboard.data[8], dashboard.data[9]]"
            :color="['#f86c6b', '#067351']"
            v-if="this.checkPermission('admin.dashboard.days.resitered_count')"
            :startDate="startDate"
            :endDate="endDate"
          ></Chart>
        </b-col>
        <b-col md="6">
          <Chart
            title="Зээлийн тоо"
            :legends="['Гарсан зээлийн тоо', 'Хаасан зээлийн тоо']"
            type="LOAN_COUNT"
            :color="['#f86c6b', '#067351']"
            :data="[dashboard.data[10], dashboard.data[16]]"
            v-if="this.checkPermission('admin.dashboard.days.loan_count')"
            :startDate="startDate"
            :endDate="endDate"
          ></Chart>
        </b-col>
        <b-col md="12">
          <Chart
            title="Мөнгөн дүн"
            :legends="['Гарсан зээлийн хэмжээ', 'Орсон эргэн төлөлтийн хэмжээ']"
            type="LOAN_AMOUNT"
            value_type="amount"
            :color="['#4dbd74', '#ffc107']"
            :data="[dashboard.data[11], dashboard.data[12]]"
            v-if="this.checkPermission('admin.dashboard.days.loan_amount')"
            :startDate="startDate"
            :endDate="endDate"
          ></Chart>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Chart from "./Chart";
import Card from "./Card";
import ActiveLoanCount from "./ActiveLoanCount";
import OverdueLoan from "./OverdueLoan";
import OverdueLoanType from "./OverdueLoanType";
import Star from "./Star";
import dashboard from "./data.json";
import moment from "moment";
const fs = require("browserify-fs");

export default {
  name: "dashboard",
  components: {
    Chart,
    Card,
    ActiveLoanCount,
    OverdueLoan,
    Star,
    OverdueLoanType,
  },
  data: function() {
    return {
      isLoading: false,
      day_data: [],
      since_data: [],
      dashboard,
      startDate: moment()
        .subtract(6, "days")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    };
  },
  created() {
    // if (
    //   this.$store.state.dashboard_data != undefined &&
    //   this.$store.state.dashboard_data.data != undefined
    // ) {
    //   this.$data.dashboard = this.$store.state.dashboard_data;
    //   if (this.$data.dashboard.last_update_date != null) {
    //     this.$data.startDate = moment(this.$data.dashboard.last_update_date)
    //       .subtract(6, "days")
    //       .format("YYYY-MM-DD");
    //     this.$data.endDate = moment(
    //       this.$data.dashboard.last_update_date
    //     ).format("YYYY-MM-DD");
    //   }
    // }
    // if (
    //   this.$data.dashboard.last_update_date == null &&
      // this.checkPermission("admin.dashboard.update")
    // )
  },
  methods: {
    loadData: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.$config.API_URL + "ReportWebService/get_dashboard",
          {
            data: JSON.stringify({
              token: this.$store.getters.token,
              email: this.$store.getters.email,
              startDate: moment()
                .subtract(6, "days")
                .format("YYYY-MM-DD"),
              endDate: moment().format("YYYY-MM-DD"),
              overdueDate: moment().format("YYYY-MM-DD"),
              type: "ALL",
            }),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            //console.log(response);
            if (response.body.status == 500) {
              this.isLoading = false;
              this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
            } else if (response.body.status != 1) {
              this.$data.dashboard.data = response.body.data;
              this.$data.dashboard.last_update_date = moment().format(
                "YYYY-MM-DD HH:mm:ss"
              );
              this.$store.commit("ADD_STATE", {
                key: "dashboard_data",
                value: this.$data.dashboard,
              });
            }
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
    },
  },
};
</script>
