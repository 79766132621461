<template>
  <div>
    <h5 class="mb-3">{{ title }}</h5>
    <b-row>
      <b-col>
        <b-card :class="[]">
          <span class="pull-right">нийт</span>
          <div class="h4 m-0">{{ total.count | number }}</div>
          <div>
            <span class="text-primary">{{ total.amount | number }}₮</span>
          </div>
        </b-card>
      </b-col>
      <b-col :key="index" v-for="(item, index) in this.datas">
        <b-card :class="[]">
          <span class="pull-right">{{ $store.getters.loan_type[index] }}</span>
          <div class="h4 m-0">{{ item.count | number }}</div>
          <div>
            <span class="pull-right badge bg-primary mt-1"
              >{{ ((item.amount / total.amount) * 100) | currecry }}%</span
            >
            <span class="text-primary">{{ item.amount | number }}₮</span>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Card from "./Card";
import ActiveLoanCountCell from "./ActiveLoanCountCell";
import moment from "moment";

export default {
  name: "ActiveLoanCount",
  components: {
    Card,
    ActiveLoanCountCell,
  },
  props: {
    title: {
      type: String,
      default: "Идэвхтэй зээл",
    },
    url: {
      type: String,
      default: "ReportWebService/get_dashboard",
    },
    type: {
      type: String,
      default: "ACTIVE_LOAN_COUNT",
    },
    value_type: {
      type: String,
      default: "",
    },
    startDate: {
      type: String,
      default: "2000-01-01",
    },
    endDate: {
      type: String,
      default: moment().format("YYYY-MM-DD"),
    },
    color: {
      type: String,
      default: "#067351",
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    total: {
      count: 0,
      amount: 0,
    },
    datas: {
      APP: {
        count: 0,
        amount: 0,
      },
      PURCHASE: {
        count: 0,
        amount: 0,
      },
      CREDIT: {
        count: 0,
        amount: 0,
      },
      AUTO_MACHINE: {
        count: 0,
        amount: 0,
      },
    },
    type_index_to_key: ["APP", "PURCHASE", "CREDIT", "AUTO_MACHINE"],
    terms: [],
    show_detail: false,
  }),
  created() {
    let _terms = [];
    for (var i in this.data) {
      var info_data = this.data[i];
      if (info_data.length > 0) {
        let _count = info_data[0][0];
        let _amount = info_data[0][1];

        let loan_type = this.$data.type_index_to_key[i];

        this.$data.datas[loan_type].count += _count;
        this.$data.datas[loan_type].amount += _amount;

        this.$data.total.count += _count;
        this.$data.total.amount += _amount;
      }
    }
  },
  methods: {
    showDetail: function() {
      this.$data.show_detail = !this.$data.show_detail;
    },
  },
};
</script>
